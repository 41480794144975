import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { Notification } from "./notification";
import { ApiService } from "../../api/api.module";
import { Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

@Injectable()
export class NotificationService {

  constructor (private _apiService: ApiService) {}

  list(): Observable<Notification[]> {
    return this
      ._apiService
      .get('notifications/badges')
      .map(
        res => {
          let response = res.json();
          return response;
        },
        error =>{
          return [];
        });
  }
}
