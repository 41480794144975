
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ApiService } from '../../../../../api/api.service';
import { User } from '../../../../../models/models.module';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/user4.jpg';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;
	user:User;

	constructor (
		private _apiService : ApiService,
		private router: Router,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit (): void {
		this.user = JSON.parse(localStorage.getItem("user"));
	}

	public logout () {
		this._apiService.signOut();
	}

	goToProfile(){
		this.router.navigateByUrl(`${localStorage.getItem('userRole')}/profilo`);
	}
}
