import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "orderbyTime"
})
export class OrderByTimePipe implements PipeTransform {
  transform(array: Array<string>, args: string): Array<string> {
    if(array){
      array.sort((a: any, b: any) => {
        if(a && b && a.time_slots && b.time_slots && a.time_slots[0] && b.time_slots[0]){
          if (a.time_slots[0].start_time < b.time_slots[0].start_time) {
            return -1;
          } else if (a.time_slots[0] > b.time_slots[0].start_time) {
            return 1;
          }
        }
          return 0;
      });
    }
    return array;
  }
}
