import { Angular2TokenService } from 'angular2-token';
import { Injectable } from '@angular/core';
import { SignInData } from 'angular2-token';
import { RegisterData, ResetPasswordData } from 'angular2-token';
import { Observable } from "rxjs/Observable";
import { Response, Http } from '@angular/http';
import { UpdatePasswordData } from 'angular2-token';
import { ActivatedRoute, Router } from "@angular/router";

export class ApiServiceConfig {
  apiBase?: string
  signInPath?: string
  signInStoredUrlStorageKey?: string
  signOutPath?: string
  validateTokenPath?: string
  signOutFailedValidate?: boolean
  registerAccountPath?: string
  deleteAccountPath?: string
  registerAccountCallback?: string
  updatePasswordPath?: string
  resetPasswordPath?: string
  resetPasswordCallback?: string
  oAuthPaths?: any
  oAuthCallbackPath?: string
  userTypes?: any
  signedInRedirect?: string
  signedOutRedirect?: string
  signInCondition?: (res : Response) => boolean
}

@Injectable()
export class ApiService extends Angular2TokenService {

  private myRouter: Router;

  constructor( private _http : Http, _activatedRoute : ActivatedRoute, router : Router) {
    super(_http, _activatedRoute, router);
    this.myRouter = router;
  }

  authConfig: ApiServiceConfig

  get currentUser() {
    return this.currentUserData;
  }

  init(config: ApiServiceConfig) : void {
    this.authConfig = config;
    if(config.signInCondition) {
      this.authConfig.signInCondition = config.signInCondition;
    }
    else {
      this.authConfig.signInCondition = (res : Response) => { return true; }
    }

    super.init({
      apiBase:                    config.apiBase || 'http://localhost:4200',
      apiPath:                    null,

      signInPath:                 config.signInPath || 'auth/login',
      signInRedirect:             'home',
      signInStoredUrlStorageKey:  config.signInStoredUrlStorageKey || null,

      signOutPath:                config.signOutPath || 'auth/logout',
      //validateTokenPath:          config.validateTokenPath || 'auth/validate_token',
      signOutFailedValidate:      true,

      registerAccountPath:        config.registerAccountPath || 'auth/register',
      deleteAccountPath:          config.deleteAccountPath || 'auth/delete_account',
      registerAccountCallback:    config.registerAccountCallback || window.location.href,

      updatePasswordPath:         config.updatePasswordPath || 'auth/password',
      resetPasswordPath:          config.resetPasswordPath || 'auth/password',
      resetPasswordCallback:      config.resetPasswordCallback || window.location.href,

      oAuthPaths:                 config.oAuthPaths || {
                                    facebook: 'auth/facebook'
                                  },

      oAuthCallbackPath:          config.oAuthCallbackPath || 'oauth_callback',
      oAuthWindowType:            'newWindow',
      oAuthWindowOptions:         null,

      userTypes:                  config.userTypes,

      globalOptions: {
        headers: {
          'Content-Type':     'application/json',
          'Accept':           'application/json',
        }
      }
    });
    
    if(config.apiBase){
      localStorage.setItem("apiBase", config.apiBase);
    }
  }

  signIn(signInData: SignInData){
    localStorage.clear();
    let obs : Observable<Response> = super.signIn(signInData);

    obs.subscribe(
      res => {
        if(this.authConfig.signInCondition(res)){
          let role = res.json().user.role;
          if(!role){
            role = 'admin';
          }
          localStorage.setItem('prettyUserRole', role);
          localStorage.setItem('userRole', role);
          localStorage.setItem('userId', res.json().user.id);
          localStorage.setItem('user', JSON.stringify(res.json().user));
        }
        else{
          this.signOut();
        }
      },
      error =>{
      }
    );

    return obs;
  }


  validateTwoFactor(code){
    return this
    .post('validate_two_factor_authentication', {'code':code})
    .map(response => {
      return response.json().user;
    })
  }

  signOut(){
    let obs : Observable<Response> = super.signOut();
    localStorage.clear();
    obs.subscribe(
      res => {
        if(this.authConfig.signedOutRedirect) this.myRouter.navigateByUrl(this.authConfig.signedOutRedirect);
      },
      error => {
        if(this.authConfig.signedOutRedirect) this.myRouter.navigateByUrl(this.authConfig.signedOutRedirect);
      }
    )

    return obs;
  }


  requestPasswordReset(redirect_url, email){
    return this
    .post('auth/password', {'redirect_url': redirect_url, 'email': email})
    .map(response => {
      return response.json();
    })
  }

  requestPasswordResetStep2(updatePasswordData: UpdatePasswordData, headers){
    return this._http.put(localStorage.getItem('apiBase') + '/auth/password', {'password': updatePasswordData.password, 'password_confirmation': updatePasswordData.passwordConfirmation}, {headers: headers});
  }

  me(){
    return this
    .get('me')
    .map(response => {
      return response.json().hospitals;
    })
  }
}
