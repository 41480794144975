import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { User } from "./user";
import { ApiService } from "../../api/api.module";
import { Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {

  constructor (private _apiService: ApiService, private _mh: ModelHelperService) {}

  index(): Observable<User[]> {
    return this
      ._apiService
      .get('users')
      .map(response => {
        let res = response.json();
        return res['users'];
      });
  }

  indexDoctor(): Observable<User[]> {
    return this
      ._apiService
      .get('users/doctors')
      .map(response => {
        let res = response.json();
        return res['users'];
      });
  }
  

  create(user: User): Observable<User> {
    return this
      ._apiService
      .post('users', { "user": user })
      .map(response =>
        this._mh.assign(User, response.json().user) as User
      );
  }

  list(user:User,page: number = 0, per_page: number = 30, order_by:string, direction:string): Observable<User[]> {
    page = page + 1;
    return this
      ._apiService
      .get('users' 
        +
        (page ? ("?page=" + page) : "") +
        (per_page ? ('&per_page=' + per_page) : "") +
        (order_by ? ("&order_by=" + order_by) : "") +
        (direction ? ("&direction=" + direction) : "") 
      )
      .map(
        res => {
          let response = res.json();
          return response['users'];
        },
        error =>{
          return [];
        });
  }

  showUser(user_id): Observable<User> {
    return this
      ._apiService
      .get('users/' + user_id)
      .map(response => this._mh.assign(User, response.json()) as User);
  }

  updateUser(user): Observable<User> {
    return this
      ._apiService
      .put('users/' + user.id, { "user": user })
      .map(response => this._mh.assign(User, response.json()) as User);
  }

  deleteUser(user_id): Observable<User> {
    return this
      ._apiService
      .delete('users/' + user_id)
      .map(response => this._mh.assign(User, response.json()) as User);
  }
  
  initUser(): Observable<Object> {
    return this
      ._apiService
      .get('users/init')
      .map(response => this._mh.assign(Object, response.json()) as Object);
  }

}
