import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { Tag } from "./tag";
import { ApiService } from "../../api/api.module";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { City } from "../models.module";


@Injectable()
export class TagService {

  private headers: HttpHeaders;

  constructor (private _apiService: ApiService, private _mh: ModelHelperService, private http: HttpClient) {
  }


  create(tag: Tag): Observable<Tag> {
    return this
      ._apiService
      .post('tags', { "tag": tag })
      .map(
        response => this._mh.assign(Tag, response.json().tag) as Tag,
      );
  }

  list(page: number = 0, per_page: number = 30, order_by:string, direction:string, filters: Object): Observable<Object[]> {
    page = page + 1;
    return this
      ._apiService
      .get('tags'
        +
        (page ? ("?page=" + page) : "") +
        (per_page ? ('&per_page=' + per_page) : "") +
        // (order_by ? ("&order_by=" + order_by) : "") +
        // (direction ? ("&direction=" + direction) : "") +
        (filters && filters["name"] ? ('&name=' + filters["name"]) : "") +
        (filters && filters["art_movement"] ? ('&art_movement=' + filters["art_movement"]) : "") +
        (filters && filters["first_char"] ? ('&first_char=' + filters["first_char"]) : "") +
        (order_by ? ('&sort=' + order_by + '_' + direction) : "")
      )
      .map(
        res => {
          let response = res.json();
          return response;
        },
        error =>{
          return [];
        });
  }

  showTag(tag_id): Observable<Tag> {
    return this
      ._apiService
      .get('tags/' + tag_id)
      .map(response => this._mh.assign(Tag, response.json().tag) as Tag);
  }

  updateTag(tag): Observable<Tag> {
    return this
      ._apiService
      .put('tags/' + tag.id, { "tag": tag })
      .map(response => this._mh.assign(Tag, response.json().tag) as Tag);
  }

  deleteTag(tag_id): Observable<Tag> {
    return this
      ._apiService
      .delete('tags/' + tag_id)
      .map(response => this._mh.assign(Tag, response.json()) as Tag);
  }

  findTag(name): Observable<Object[]> {
    return this
      ._apiService
      .post('tags/find_by_name', {'name':name})
      .map(response => response.json().tags);
  }

}
