import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ApiService } from './api.service';

@Injectable()
export class RoleGuardService implements CanActivate {

  constructor(public auth: ApiService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let expectedRole = route.data.expectedRole;

    let role = localStorage.getItem('userRole');
    if(expectedRole != role){
      this.router.navigate(['auth']);
      return false;
    }
    return true;
  }

}
