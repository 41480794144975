import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './api-routing.module';
import { FormsModule } from '@angular/forms';
import { ApiService } from './api.service';
import { LoginComponent, DialogResetPwd } from "./login/login.component";
import { ResetPwdComponent } from "./reset-pwd/reset-pwd.component";
import { RoleGuardService } from './role-guard.service';
import { UiModule } from '../ui/ui.module';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { SpinnerButtonModule } from '../content/partials/content/general/spinner-button/spinner-button.module';
import {
	MatButtonModule,
	MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatCheckboxModule,
  MatRadioModule
} from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    UiModule,
    SpinnerButtonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule
  ],
  declarations: [
    LoginComponent,
    ResetPwdComponent,
    AuthNoticeComponent,
    DialogResetPwd
  ],
  exports: [
  ],
  entryComponents: [DialogResetPwd]
})

export class ApiModule { }

export { ApiService, RoleGuardService, LoginComponent }
export { SignInData, UserData } from 'angular2-token';
