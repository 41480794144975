import { Tag } from "../tag/tag";

export class Book {
  id:string;
  books_related: any;
  relates_books: any;
  authenticity_certificate: boolean;
  awards: string;
  code: string;
  collocation:string;
  contacts: string;
  current_status_of_work:string;
  customs_note: boolean;
  depth_it: number;
  depth_en: number;
  description_en: string;
  description_it: string;
  documentations: string;
  duration:string;
  edition: string;
  environmental_dimension: string;
  year: string;
  fund: string;
  height_it: number;
  height_en: number;
  item_count:number;
  market_price_amount: number;
  market_price_currency:string;
  market_price_notes: string;
  market_price_vat: string;
  other_collocation: string;
  other_current_status: string;
  other_fund: string;
  other_provenance: string;
  pages: number;
  provenance: string;
  published: boolean;
  purchase_price_amount: number;
  purchase_price_currency: string;
  purchase_price_notes: string;
  purchase_price_vat: string;
  resolution: string;
  title: string;
  unit_of_measure: string;
  video_url: string;
  width_it: number;
  width_en: number;
  year_of_acquisition: number;
  artist_ids: string [];
  tag_ids: string[];
  photo: any;
  documentation_pdf: Array<Object>;
  authenticity_certificate_pdf: Object;
  customs_note_pdf: Object;
  images: any;
  artists?: any;
  tags?:Array<Tag>;
  selected?: any;
  f_24?: boolean;
  f_24_pdf: any;
  market_price_total_amount: string;
  purchase_price_total_amount: string;
}


