import { MatSnackBarConfig, MatSnackBar } from "@angular/material";
import { Injectable } from "@angular/core";

@Injectable()
export class SnackBarService {
   // private snackBar: MatSnackBar;
  
    constructor(private snackBar: MatSnackBar) { }
  
    showSnackBar(msg){
		let config = new MatSnackBarConfig();
        config.verticalPosition = 'bottom';
        config.panelClass = 'success';
        config.duration = 5000;
        this.snackBar.open(msg, 'OK', config);
	}

	showErrorSnackBar(msg : string){
	let config = new MatSnackBarConfig();
		config.verticalPosition = 'bottom';
		config.panelClass = 'error';
		config.duration = 5000;
		this.snackBar.open(msg, 'OK', config);
	}
  }