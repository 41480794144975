import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuAsideOffcanvasDirective } from '../../../core/directives/menu-aside-offcanvas.directive';
import { ClassInitService } from '../../../core/services/class-init.service';
import { LayoutConfigService } from '../../../core/services/layout-config.service';
import { LayoutRefService } from '../../../core/services/layout/layout-ref.service';
import { MenuAsideService } from '../../../core/services/layout/menu-aside.service';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../../api/api.service';
import { catchError, map, switchMap, filter, debounceTime, finalize, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import {SnackBarService, City, UserService, NotificationService} from '../../../models/models.module';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'm-aside-left',
	templateUrl: './aside-left.component.html',
	styleUrls: ['./aside-left.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLeftComponent implements OnInit, AfterViewInit {

	@HostBinding('class') classes = 'm-grid__item m-aside-left';
	@HostBinding('id') id = 'm_aside_left';

	@HostBinding('attr.mMenuAsideOffcanvas') mMenuAsideOffcanvas: MenuAsideOffcanvasDirective;

	currentRouteUrl: string = '';
	insideTm: any;
	  outsideTm: any;
	  notif_trackings:number;
	  notif_documents:number;
	constructor(
		private el: ElementRef,
		public classInitService: ClassInitService,
		public menuAsideService: MenuAsideService,
		public layoutConfigService: LayoutConfigService,
		private router: Router,
		private layoutRefService: LayoutRefService,
		private _apiService : ApiService,
		public dialog: MatDialog,
		private _notificationService: NotificationService,
		private cdr: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document
	) {
		// subscribe to menu classes update
		this.classInitService.onClassesUpdated$.subscribe(classes => {
			// join the classes array and pass to variable
			this.classes = 'm-grid__item m-aside-left ' + classes.aside_left.join(' ');
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(this.el);
			// manually call the directives' lifecycle hook method
			this.mMenuAsideOffcanvas.ngAfterViewInit();

			// keep aside left element reference
			this.layoutRefService.addElement('asideLeft', this.el.nativeElement);
			//this.getNotifications();
		});
	}

	ngOnInit() {
		this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => this.currentRouteUrl = this.router.url.split(/[?#]/)[0]);

	}

	isMenuItemIsActive(item): boolean {
		if (item.submenu) {
			return this.isMenuRootItemIsActive(item);
		}

		if (!item.page) {
			return false;
		}

		// dashboard
		if (item.page !== '/' && this.currentRouteUrl.startsWith(item.page)) {
			return true;
		}
		return this.currentRouteUrl === item.page;
	}

	isMenuRootItemIsActive(item): boolean {
		let result: boolean = false;

		for (const subItem of item.submenu) {
			result = this.isMenuItemIsActive(subItem);
			if (result) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseEnter(e: Event) {
		// check if the left aside menu is fixed
		if (this.document.body.classList.contains('m-aside-left--fixed')) {
			if (this.outsideTm) {
				clearTimeout(this.outsideTm);
				this.outsideTm = null;
			}

			this.insideTm = setTimeout(() => {
				// if the left aside menu is minimized
				if (this.document.body.classList.contains('m-aside-left--minimize') && mUtil.isInResponsiveRange('desktop')) {
					// show the left aside menu
					this.document.body.classList.remove('m-aside-left--minimize');
					this.document.body.classList.add('m-aside-left--minimize-hover');
				}
			}, 300);
		}
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseLeave(e: Event) {
		if (this.document.body.classList.contains('m-aside-left--fixed')) {
			if (this.insideTm) {
				clearTimeout(this.insideTm);
				this.insideTm = null;
			}

			this.outsideTm = setTimeout(() => {
				// if the left aside menu is expand
				if (this.document.body.classList.contains('m-aside-left--minimize-hover') && mUtil.isInResponsiveRange('desktop')) {
					// hide back the left aside menu
					this.document.body.classList.remove('m-aside-left--minimize-hover');
					this.document.body.classList.add('m-aside-left--minimize');
				}
			}, 500);
		}
	}

	logout () {
		this._apiService.signOut();
	}

	getNotifications(){
		let user_role = localStorage.getItem('userRole');
		//if(user_role == 'admin') return;
		this._notificationService
		.list().subscribe((data: any) => {
			this.notif_trackings = data["trackings"];
			this.notif_documents = data["documents"];
			if(this.notif_documents > 0){
				let doc = document.getElementById("DOCUMENTI");
				if(doc){
					let first_span = doc.firstElementChild;
					if(first_span && first_span.classList[0] == 'm-menu__link-badge'){
						first_span.firstElementChild.innerHTML = this.notif_documents + '';
					}
					else{
						let span = document.createElement('span');
						span.classList.add('m-menu__link-badge');
						let span1 = document.createElement('span');
						span1.classList.add('m-badge');
						span1.innerHTML = this.notif_documents + '';
						span.appendChild(span1);
						doc.appendChild(span);
					}
				}
			}
			if(this.notif_trackings > 0){
				let trac = document.getElementById("TRACCIAMENTI");
				if(trac){
					let first_span = trac.firstElementChild;
					if(first_span && first_span.classList[0] == 'm-menu__link-badge'){
						first_span.firstElementChild.innerHTML = this.notif_trackings + '';
					}
					else{
						let span = document.createElement('span');
						span.classList.add('m-menu__link-badge');
						let span1 = document.createElement('span');
						span1.classList.add('m-badge');
						span1.innerHTML = this.notif_trackings + '';
						span.appendChild(span1);
						trac.appendChild(span);
					}
				}
			}
			this.cdr.detectChanges();
		})
	}

}
