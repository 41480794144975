import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { PrettyModelPipe } from './pretty-model.pipe';
import { ModelHelperService } from './model-helper.service';
import { OrderByTimePipe } from './orderby-time.pipe';


import {
  Region,
  RegionService
} from './region/region.model';

import {
  Province,
  ProvinceService
} from './province/province.model';

import {
  City,
  CityService
} from './city/city.model';

import {
  Country,
  CountryService
} from './country/country.model';

import { Artist } from './artist/artist';
import { ArtistService } from './artist/artist.service';

import { Artwork } from './artwork/artwork';
import { ArtworkService } from './artwork/artwork.service';

import { Tag } from './tag/tag';
import { TagService } from './tag/tag.service';

import { User } from './user/user';
import { UserService } from './user/user.service';

import { Book } from './book/book';
import { BookService } from './book/book.service';

import { SnackBarService } from './snackbar.service';

import { Notification } from './notification/notification';
import { NotificationService } from './notification/notification.service';

import { RoleService} from './role.service';





@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PrettyModelPipe,
    OrderByTimePipe
  ],
  providers: [
    ModelHelperService,
    ArtistService,
    RegionService,
    ProvinceService,
    UserService,
    CountryService,
    SnackBarService,
    NotificationService,
    ArtworkService,
    BookService,
    TagService,
    RoleService
  ],
  exports: [
    PrettyModelPipe,
    OrderByTimePipe
  ]
})
export class ModelsModule {}

export {
  Region,
  RegionService,

  Province,
  ProvinceService,

  City,
  CityService,

  Artist,
  ArtistService,

  Artwork,
  ArtworkService,

  User,
  UserService,

  ModelHelperService,
  PrettyModelPipe,

  Country,
  CountryService,

  SnackBarService,

  Notification,
  NotificationService,

  Tag,
  TagService,
  
  Book,
  BookService,
  RoleService
};
