import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { City } from "./city.model";
import { ApiService } from "../../api/api.module";
import { Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';

@Injectable()
export class CityService {

  constructor (private _apiService: ApiService) {}

  list(provinceId): Observable<Response> {
    return this
      ._apiService
      .get('v1/cities/find_by_province/' +  provinceId)
      .map(response =>response.json() );
  }

  listCities(search): Observable<City[]> {
    return this
      ._apiService
      .get('v1/cities/find_by_name?q=' + search)
      .map(
        res => {
          let response = res.json();
          return response['cities'];
        },
        error =>{
          return [];
        });
  }
}
