import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/Observable";

@Injectable()
export class DiagnosisService {
  private headers: HttpHeaders;
  private amazonHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "access-token": localStorage.getItem("accessToken"),
      "token-type": "Bearer",
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid")
    });
  }

  setPathology(pathology: any, medical_id: string) {
    return this.http
      .put(`${environment.apiUrl}/medical_records/${medical_id}/pathology`, pathology, {
        headers: this.headers
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  setTherapy(therapy: any, medical_id: string) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "access-token": localStorage.getItem("accessToken"),
      "token-type": "Bearer",
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid")
    });
    return this.http
      .put(`${environment.apiUrl}/medical_records/${medical_id}/therapy`, therapy, {
        headers: this.headers
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
