import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'm-switch-button-global',
	templateUrl: './switch-button-global.component.html',
	styleUrls: ['./switch-button-global.component.scss']
})
export class SwitchButtonGlobalComponent implements OnInit {
	@Output() emitter: EventEmitter<any[]> = new EventEmitter();
	@Input() forceCheck;
	checkboxValue: boolean;

	isChecked:any = false;

    ngOnInit() {
	}
	
	checkValue(event){
		this.emitter.emit(event);
	}
}