import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { Country } from "./country.model";
import { ApiService } from "../../api/api.module";
import { Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

@Injectable()
export class CountryService {

  constructor (private _apiService: ApiService) {}

  listCountries(search): Observable<Country[]> {
    return this
      ._apiService
      .get('v1/countries/find_by_name?q=' + search)
      .map(
        res => {
          let response = res.json();
          return response['countries'];
          // if(response.search == search){
          //   return response['countries'];
          // }
          // return [];
        },
        error =>{
          return [];
        });
  }
}
