// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isMockEnabled: false,
  //apiUrl:'http://fondazione-arte-crt-api.tandu.it', //staging
  apiUrl: 'https://api.fondazioneartecrt.it',
  firebase: {
    apiKey: "your apikey",
    authDomain: "your authDomain",
    databaseURL: "your databaseUrl",
    projectId: "your projectId",
    storageBucket: "your storageBucket",
    messagingSenderId: "your messagingSenderId"
  }
};
