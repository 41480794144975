// tslint:disable-next-line:no-shadowed-variable
import {ConfigModel} from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		let user_role = localStorage.getItem('userRole');
		if(user_role == 'admin'){
			this.config = {
			aside: {
				self: {},
				items: [
					{
						section: ""
					},
					{
						title: 'Opere d\'arte',
						root: true,
						icon: 'icon-opere',
						page: '/admin/opere',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Artisti',
						root: true,
						toggle: 'click',
						icon: 'icon-artisti',
						page: '/admin/artisti',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Libri d\'artista',
						root: true,
						icon: 'icon-libri',
						page: '/admin/libri-artista',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Tag',
						root: true,
						icon: 'icon-tag',
						page: '/admin/tag',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  }
				]
			}
			}
		}
		if(user_role == 'viewer'){
			this.config = {
			aside: {
				self: {},
				items: [
					{
						section: ""
					},
					{
						title: 'Opere d\'arte',
						root: true,
						icon: 'icon-opere',
						page: '/viewer/opere',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Artisti',
						root: true,
						toggle: 'click',
						icon: 'icon-artisti',
						page: '/viewer/artisti',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Libri d\'artista',
						root: true,
						icon: 'icon-libri',
						page: '/viewer/libri-artista',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Tag',
						root: true,
						icon: 'icon-tag',
						page: '/viewer/tag',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  }
				]
			}
			}
		}
		if(user_role == 'editor'){
			this.config = {
			aside: {
				self: {},
				items: [
					{
						section: ""
					},
					{
						title: 'Opere d\'arte',
						root: true,
						icon: 'icon-opere',
						page: '/editor/opere',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Artisti',
						root: true,
						toggle: 'click',
						icon: 'icon-artisti',
						page: '/editor/artisti',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Libri d\'artista',
						root: true,
						icon: 'icon-libri',
						page: '/editor/libri-artista',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  },
					  {
						title: 'Tag',
						root: true,
						icon: 'icon-tag',
						page: '/editor/tag',
						toggle: 'click',
						disabled: false,
						disableRouterLink: false
					  }
				]
			}
			}
		}
	}
}
