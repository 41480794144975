import { Province } from '../province/province.model';
import { Region } from '../region/region.model';
export { CityService } from './city.service';

export class City {
  id: number;
  name: string;
  province: Province;
  region: Region;
}
