import { Directive } from '@angular/core';
import { ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[colorStatus]'
})
export class ColorStautsDirective {
  @Input('colorStatus') pippo: string;
  folder_statuses: any;

  constructor(el: ElementRef) {
    //el.nativeElement.style.backgroundColor = 'yellow';
    this.folder_statuses = JSON.parse(localStorage.getItem("colorStatus"));
    this.getColor(this.pippo, el);
 }


 getColor(status, el){
  if(this.folder_statuses && this.folder_statuses.length > 0){
    this.folder_statuses.forEach(function (value) {
      if(value["status"] === status){
        el.nativeElement.style.backgroundColor = 'yellow';
      }
  });
  }
}
}