import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'm-guest-mode',
	templateUrl: './guest-mode.component.html',
	styleUrls: ['./guest-mode.component.scss']
})
export class GuestModeComponent implements OnInit {
    ngOnInit() {
    }
}