import {Component, ViewChild, OnInit, ElementRef, Renderer2,Injectable, Input, EventEmitter, Output} from '@angular/core';
import {
    NgbDatepicker, 
    NgbInputDatepicker, 
    NgbDateStruct, 
    NgbCalendar, 
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
    
import {NgModel} from "@angular/forms";
import * as moment from 'moment';
import {Subscription} from 'rxjs';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const I18N_VALUES = {
  'it': {
    weekdays: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'],
    months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  }
  // other languages you would support
};
@Injectable()
export class I18n {
  language = 'it';
}

@Component({
  selector: 'ngbd-date-picker',
  templateUrl: './date-picker.component.html',
  styles: [`
  .ngb-dp-day.disabled{
    background: rgba(0,0,0,0.12);
  }
    .btn-link{
      color: #00c5dc;
    }
    .ngb-dp-day{
      outline: none;
    }
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
      outline: none;
    }
    .custom-day.focused {
      background-color: #00c5dc;
    }
    .custom-day.range, .custom-day:hover {
      background-color: #00c5dc;
      color: white;
      outline: none;
    }
    .custom-day.faded {
      background-color: rgba(0, 197, 220, 0.5);
    }
    .btn-lightbg-primary.text-white {
      background-color: #00c5dc !important;
  }
  `],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: NgbdDatePicker}]
})
@Injectable()
export class NgbdDatePicker extends NgbDatepickerI18n implements OnInit {ù

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  startDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    hoveredDate: NgbDateStruct;
    fromDate: any;
    toDate: any;
    model: any;
    private _subscription: Subscription;
    private _selectSubscription: Subscription;
    @ViewChild("d") input: NgbInputDatepicker;
    @ViewChild(NgModel) datePick: NgModel;
    @ViewChild('myRangeInput') myRangeInput: ElementRef;
    @Input ('folder') folder: Object;
    @Input ('date') initDate: any;
    @Input('disableBefore') disableBefore: boolean;
    @Input('isInputReadonly') isInputReadonly : boolean = false;
    @Output() getDataChange = new EventEmitter<any>();

    isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
    isInside = date => after(date, this.fromDate) && before(date, this.toDate);
    isFrom = date => equals(date, this.fromDate);
    isTo = date => equals(date, this.toDate);

    constructor(
      element: ElementRef, 
      private renderer: Renderer2, 
      private _parserFormatter: NgbDateParserFormatter,
      private _i18n: I18n) {
        super();
    }
    ngOnInit() {
        this.startDate = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        this.minDate = {year: now.getFullYear() - 200, month: now.getMonth() + 1, day: now.getDate()};
        if(this.initDate){
          this.model = this.initDate;
        }
        if(this.disableBefore){
          this.minDate = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        }
    }

    onDateSelection(date: NgbDateStruct) {
      let parsed = '';
      if(date && date.year && this.folder){
        let mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid()) return '';
        this.folder["birthday"] =  mdt.format('YYYY-MM-DD');
        this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
      }else{
        this.initDate = date;
        this.getDataChange.emit(date);
      }
    }

    reset(){
      this.model = ''; 
      this.folder['birthday'] = ''; 
    }

}
