export class User {
  id?: number;
  name?:string;
  firstname?: string;
  lastname?: string;
  hospital?:string;
  phone_number?:string;
  email?: string;
  created_at?:string;
  update_at?:string;
  role?:string;
}
