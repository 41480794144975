import { Pipe, PipeTransform } from '@angular/core';

import {
  User
} from './models.module';

@Pipe({
  name: 'prettyModel'
})
export class PrettyModelPipe implements PipeTransform {

  transformCollection(value: any[], maxCount: number = 2) : string {
    if(value == null || value.length == 0) return '';
    if(value.length == 1 && maxCount >= 1) return this.transform(value[0]);
    if(value.length > 1 && value.length <= maxCount){
      let output = '';
      for(let i=0; i<value.length; i++){
        if(output.length > 0) output += ', ';
        output += this.transform(value[i]);
      }
      return output;
    }
    if(value.length > maxCount) return value.length + (value.length == 1 ? ' elemento' : ' elementi');
  }

  translate(value: string, translations: Object, fallback: string){
    return translations[value] || fallback || value;
  }

  transform(value: any, args?: string|number, fallback?: string): string {
    if(value == null) return '';
    if(value instanceof Array) {
      if(typeof args === 'string'){
        console.warn('PrettyPipe called with string argument on array: ')
        console.warn(value)
      }
      return this.transformCollection(value, args as number);
    }

    // Try to transform with known pipe
    let pipe : PipeTransform = null;
    // if (value instanceof GolfClub) pipe = new PrettyGolfPipe();
    if (pipe != null) return pipe.transform(value);

    // Try to transform with given string translation
    if (typeof args === 'string' && typeof value === 'string' && this._translations[args]){
      return this.translate(value, this._translations[args], fallback)
    }
    if (typeof value === 'boolean'){
      return value ? 'Si' : 'No';
      //return this.translate(value+'', this._translations[args], fallback)
    }
    if (value === 'priority'){
      return value ? 'Alta' : 'Normale';
      //return this.translate(value+'', this._translations[args], fallback)
    }

    console.warn('Unable to apply PrettyPipe to: ');
    console.warn(value);
    return fallback || value.toString();
  }

  private _translations = {
    gender: {
      femmina: 'F',
      maschio: 'M'
    },
    typology:{
        'dipinto': 'Dipinto',
        'disegno': 'Disegno',
        'video': 'Video',
        'scultura': 'Scultura',
        'installazione': 'Installazione',
        'stampa': 'Stampa',
        'fotografia': 'Fotografia',
        'tecnica_mista': 'Tecnica mista',
        'performance': 'Performance'
    },
    collocation:{
      'castello_rivoli': 'Castello di Rivoli',
      'gam': 'GAM',
      'ogr': 'Ogr',
      'altro': 'Altro'
    },
    duration:{
      '30': '30 min',
      '60': '1 ora',
      '90': '1.30 ore'
    },
    reservation_status:{
      'in_attesa_di_pagamento': 'In attesa di pagamento'
    },
    month:{
      '01': 'Gennaio',
      '02': 'Febbraio',
      '03': 'Marzo',
      '04': 'Aprile',
      '05': 'Maggio',
      '06': 'Giugno',
      '07': 'Luglio',
      '08': 'Agosto',
      '09': 'Settembre',
      '10': 'Ottobre',
      '11': 'Novembre',
      '12': 'Dicembre'
    },
    flag:{
      true: 'Si',
      false: 'No'
    },
    visit:{
      'prima_visita': 'Prima visita',
      'controllo': 'Visita di controllo',
      'comunicazione': 'Comunicazione',
      'rivalutazione_terapeutica': 'Rivalutazione terapeutica'
    },
    fund:{
      'collezione_stein': 'Fondo Collezione Stein',
      'videoteca_gam': 'Videoteca GAM',
      'ogr_award': 'Ogr Award',
      'altro': 'Altro',
      'giorgio_maffei': 'Fondo Giorgio Maffei'
    }

    }
}
