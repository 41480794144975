import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SpinnerButtonModule } from '../content/partials/content/general/spinner-button/spinner-button.module';
import {
	MatButtonModule,
	MatFormFieldModule,
	MatInputModule,
  MatCheckboxModule,
  MatRadioModule
} from '@angular/material';
import {
  NgbDropdownModule,
  NgbModule,
  NgbPopover,
  NgbTabsetModule,
  NgbDatepicker,
  NgbDatepickerModule,
  NgbDatepickerConfig,
  NgbDateParserFormatter,
  NgbDateAdapter
} from "@ng-bootstrap/ng-bootstrap";
import { NgbdDaterangePicker} from './daterange-picker/daterange-picker.component';
import { NgbdDatePicker } from './date-picker/date-picker.component';
import { NgbDateITParserFormatter } from './daterange-picker/ngb-date-it-parser-formatter';
import {NgbUTCStringAdapter} from './daterange-picker/ngb-UTC-string-adapter';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { ColorStautsDirective } from './color-status.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    SpinnerButtonModule,
    NgbDropdownModule,
    NgbModule,
  ],
  declarations: [
    NgbdDaterangePicker,
    NgbdDatePicker,
    InlineEditComponent,
    ColorStautsDirective
  ],
  exports: [
    NgbdDaterangePicker,
    NgbdDatePicker,
    InlineEditComponent,
    ColorStautsDirective
  ],
  providers:[
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateITParserFormatter
    },
    {
        provide: NgbDateAdapter,
        useClass: NgbUTCStringAdapter
    },
  ]
})
export class UiModule { }
