import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'm-doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {
	// Doughnut
	public doughnutChartLabels: string[] = ['Da preparare', 'Da ritirare', 'Da scansionare', 'Da evadere'];
	public doughnutChartData: number[] = [10, 30, 40, 20];
	public doughnutChartType: string = 'doughnut';
	chartColors: any[] = [
		{ 
		  backgroundColor:["#716aca", "#00c5dc", "#ffc107", "#34bfa3"] 
		}];


	constructor () { }

	ngOnInit () {
	}

	// events
	chartClicked (e: any): void {
	}

	chartHovered (e: any): void {
	}
	

}
