
export class Tag {
  id?: string;
  art_movement: string;
  created_at: string;
  name_en: string;
  name_it: string;
  description_en: string;
  description_it: string;
  selected?:boolean;
}


