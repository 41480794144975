import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'm-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
	@Input() colors;
	@Input() chartData;
	@Input() chartLabels;
	chartOptions = {
		responsive: true,
		legend: { position: 'bottom' }
	  };
	
	//   chartData = [
	// 	{ data: [70, 68, 67], label: 'Minima' }
	//   ];
	
	//   chartLabels = ['9/3/2020', '11/3/2020', '12/3/2020'];
	

	ngOnInit () {

	}

	// events
	chartClicked (e: any): void {
	}

	chartHovered (e: any): void {
	}

}
