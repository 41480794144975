export class Artist {
  id?: string;
  first_name: string;
  last_name: string;
  stage_name:string;
  nationality: string;
  date_of_birth: string;
  dead_date: string;
  artist_description_it: string;
  artist_description_en: string;
  critical_sheet_it: string;
  critical_sheet_en: string;
  pdf_critical_sheets: Array<Object> = new Array<Object>();
  selected?:boolean;
}


