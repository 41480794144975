import { Injectable } from '@angular/core';


@Injectable()
export class RoleService {
constructor() { }

    public showOnlyToAdmin(): boolean {
       let local_role = localStorage.getItem('prettyUserRole');
       if(local_role == 'editor' || local_role == 'viewer' ) return false;
       return true;
    }

    public hideFromViewer(): boolean {
        let local_role = localStorage.getItem('prettyUserRole');
        if(local_role == 'viewer' ) return true;
        return false;
     }

}