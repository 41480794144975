import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { Region } from "./region.model";
import { ApiService } from "../../api/api.module";
import { Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';

@Injectable()
export class RegionService {

  constructor (private _apiService: ApiService) {}

  list(): Observable<Response> {
    return this
      ._apiService
      .get('v1/regions')
      .map(response =>response.json() );
  }
}
