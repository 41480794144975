import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { Province } from "./province.model";
import { ApiService } from "../../api/api.module";
import { Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';

@Injectable()
export class ProvinceService {

  constructor (private _apiService: ApiService) {}

  list(regionId): Observable<Response> {
    return this
      ._apiService
      .get('v1/provinces/find_by_region/' + regionId)
      .map(response =>response.json() );
  }
}
