import { ApiService } from '../api.service';
import { SignInData } from 'angular2-token';
import {
	Component,
	OnInit,
	Output,
	Input,
	ViewChild,
	OnDestroy,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
  HostBinding,
  EventEmitter,
  Inject
} from '@angular/core';
import { NgForm } from '@angular/forms';
import * as objectPath from 'object-path';
import { Subject, Subscription } from 'rxjs';
import { SpinnerButtonOptions } from '../../content/partials/content/general/spinner-button/button-options.interface';
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService } from '../../api/auth-notice/auth-notice.service';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBarConfig, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public _signInData: SignInData = <SignInData>{};
  firstname: string;
  lastname: string;
  userId: string;
	@Input() action: string;
  @ViewChild('f') f: NgForm;
  @ViewChild('f1') f1: NgForm;
	@HostBinding('class') classes: string = 'm-login__signin';
  @Output() actionChange = new Subject<string>();

	public loading = false;
  errors: any = [];
  sms_code: string;
  twoFactor: boolean = false;
  spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		fullWidth: false
  };
  spinner1: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		fullWidth: false
  };

  constructor(
    private _apiService : ApiService,
    private router: Router,
    public snackBar: MatSnackBar,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
    ) {
    }

    submit() {
		this.spinner.active = true;
		if (this.validate(this.f)) {
      this._apiService
      .signIn(this._signInData)
      .subscribe(
        res => {
          this.redirectUser(res.json().user.role);
        },
        error =>{
          this.spinner.active = false;
          if(error.json().errors.email){
            this.authNoticeService.setNotice(error.json().errors.email, 'error');
          }else if(error.json().errors.password){
            this.authNoticeService.setNotice(error.json().errors.password, 'error');
          }
          this.cdr.detectChanges();
        }
      )
		}
	}

    ngOnInit() {
    }

    validate(f: NgForm) {
      if (f.form.status === 'VALID') {
        return true;
      }

      this.errors = [];
      if (objectPath.get(f, 'form.controls.email.errors.email')) {
        this.errors.push('L\'email non è valida');
      }
      if (objectPath.get(f, 'form.controls.email.errors.required')) {
        this.errors.push('L\'email è obbligatoria');
      }

      if (objectPath.get(f, 'form.controls.password.errors.required')) {
        this.errors.push('La password è obbligatoria');
      }
      if (objectPath.get(f, 'form.controls.password.errors.minlength')) {
        this.errors.push('La password deve avere almeno 8 caratteri');
      }

      if (this.errors.length > 0) {
        this.authNoticeService.setNotice(this.errors.join('<br/>'), 'error');
        this.spinner.active = false;
      }

      return false;
    }


    showSnackBar(msg){
      let config = new MatSnackBarConfig();
          config.verticalPosition = 'top';
          config.panelClass = 'success';
         // config.duration = 6000;
          this.snackBar.open(msg, 'OK', config);
    }

    showSnackBarError(msg){
      let config = new MatSnackBarConfig();
          config.verticalPosition = 'top';
          config.panelClass = 'error';
          config.duration = 4000;
          this.snackBar.open(msg, 'OK', config);
    }

    openDialogBox(){
      const dialogRef = this.dialog.open(DialogResetPwd, {
        width: '484px',
        height: '416px',
        panelClass: 'reset-pwd',
        id:'reset-pwd',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        document.body.classList.remove('reset-pwd');
        if(result){
          this._apiService
          .requestPasswordReset('http://' + window.location.host + '/auth/reset-password', result)
          .subscribe(
            res => {
              this.showSnackBar(res.message);
            },
            error => {
            }
          )}
      },
      error =>{
      });
    }

    ngOnDestroy(): void {
      this.cdr.detach();
    }


    validateF1(f1:NgForm){
      if (f1.form.status === 'VALID') {
        return true;
      }
      return false;
    }

    redirectUser(role){
      this.router.navigateByUrl(`${localStorage.getItem('userRole')}/opere`);
    }
}

@Component({
  selector: 'dialog-reset-pwd',
  template:
  '<h1 mat-dialog-title>Hai dimenticato la password?</h1>\
  <div mat-dialog-content>\
  <p>Ti verrà inviata un\'email con le istruzioni per reimpostare la password.</p>\
  </div>\
  <div mat-dialog-actions>\
  <mat-form-field>\
  <mat-label>Inserisci mail</mat-label>\
  <input matInput type="email" name="email" autocomplete="off" [(ngModel)]="email" required/>\
</mat-form-field>\
    <div class="btn-wrapper text-center action">\
        <button type="submit" class="btn btn-accent mr-2" [mat-dialog-close]="email" cdkFocusInitial [disabled]="!email">RESETTA PASSWORD</button>\
        <button class="cancel" [mat-dialog-close]="false">Annulla</button>\
        </div>\
  </div>',
  styleUrls: ['./dialog-reset-pwd.component.scss']

})
export class DialogResetPwd {
  email:string;

  constructor(
    public dialogRef: MatDialogRef<DialogResetPwd>,
    @Inject(MAT_DIALOG_DATA) public data: DialogResetPwd) {
      document.body.classList.add('reset-pwd');
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

