import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from "../../api/api.module";;

@Injectable()

export class FileService {

    private headers: HttpHeaders;
    private amazonHeaders: HttpHeaders;

    private methodConfirmation = new BehaviorSubject('false');
    private patientConfirmation = new BehaviorSubject('false');
    currentConfirmation = this.methodConfirmation.asObservable();
    patientCurrentConfirmation = this.patientConfirmation.asObservable();

    constructor(private http: HttpClient, private _apiService: ApiService) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken'),
            'token-type': 'Bearer',
            'client': localStorage.getItem('client'),
            'uid': localStorage.getItem('uid')
        })
    }

    deletePlan(id: any) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': localStorage.getItem('accessToken'),
        'token-type': 'Bearer',
        'client': localStorage.getItem('client'),
        'uid': localStorage.getItem('uid')
    })

        return this.http.delete(environment.apiUrl + '/documents/' + id, {headers: this.headers}).pipe(map((response) => {
            return response
        }))
    }

    getDocumentPrivacy(id) {
      return this.http.get(`${environment.apiUrl}/documents/download_privacy?patient_id=${id}`, {headers: this.headers, responseType: 'blob'}).pipe(map((response) => {
        return response;
      }))
    }

    getDocumentInformation(id) {
      return this.http.get(`${environment.apiUrl}/documents/download_informato?patient_id=${id}`, {headers: this.headers, responseType: 'blob'}).pipe(map((response) => {
        return response;
      }))
    }

    getTherapeuticPlan(id) {
      return this.http.get(`${environment.apiUrl}/therapeutic_plans/download_pdf?patient_id=${id}`, {headers: this.headers, responseType: 'blob'}).pipe(map((response) => {
        return response;
      }))
    }

    uploadFile(file: File, resourceId: string, resourceType: string, title:string): Observable<any> {
      ​
          let data = {
            upload: {
              title: title,
              filename: file.name,
              type: file.type,
              owner_id: resourceId,
              owner_type: resourceType
            }
          };

          this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken'),
            'token-type': 'Bearer',
            'client': localStorage.getItem('client'),
            'uid': localStorage.getItem('uid')
          })

          this.amazonHeaders = new HttpHeaders({
            'Content-Type': data.upload.type,
             'access-token': localStorage.getItem('accessToken'),
             'token-type': 'Bearer',
             'client': localStorage.getItem('client'),
             'uid': localStorage.getItem('uid')
         })
      ​
          let uploadId;
      ​
          return this
            .http
            .post(environment.apiUrl + '/uploads', data, {headers: this.headers}).pipe(
              map((response: any) => {
                  return response;
                }
              ),
      ​
              flatMap((uploadResponse, index) => {
                uploadId = uploadResponse.upload.id;
                return this
                  .http
                  .put(uploadResponse.upload_url, file, {headers: this.amazonHeaders});
              }),
      ​
              flatMap((response, index) => {
                return this
                  .http
                  .post(environment.apiUrl + '/uploads/'+ uploadId + '/done', {}, {headers: this.headers}).pipe(
                    map((response: any) =>
                      response
                    )
                  );
      ​
              })
            );
      ​
    }

  changeConfirmation(confirmation) {
    this.methodConfirmation.next(confirmation)
  }

  changePatientConfirmation(confirmation) {
    this.patientConfirmation.next(confirmation)
  }
}
