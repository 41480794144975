import { Injectable } from '@angular/core';
import { ModelHelperService } from "../model-helper.service";
import { Artwork } from "./artwork";
import { ApiService } from "../../api/api.module";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { City } from "../models.module";

@Injectable()
export class ArtworkService {

  private headers: HttpHeaders;

  constructor (private _apiService: ApiService, private _mh: ModelHelperService, private http: HttpClient) {
  }

  list(page: number = 0, per_page: number = 30, order_by:string, direction:string, filters: Object): Observable<Object[]> {
    page = page + 1;
    return this
      ._apiService
      .get('artworks'
        +
        (page ? ("?page=" + page) : "") +
        (per_page ? ('&per_page=' + per_page) : "") +
        (filters && filters["title"] ? ('&title_code=' + filters["title"]) : "") +
        (filters && filters["code"] ? ('&code=' + filters["code"]) : "") +
        (filters && filters["artist"] ? ('&artist=' + filters["artist"]) : "") +
        (filters && filters["tag"] ? ('&tag=' + filters["tag"]) : "") +
        (filters && filters["art_movement"] ? ('&art_movement=' + filters["art_movement"]) : "") +
        (filters && filters["year"] ? ('&year=' + filters["year"]) : "") +
        (filters && filters["typology"] ? ('&typology=' + filters["typology"]) : "") +
        (filters && filters["collocation"] ? ('&collocation=' + filters["collocation"]) : "") +
        (filters && filters["fund"] ? ('&fund=' + filters["fund"]) : "") +
        (order_by ? ('&sort=' + order_by + '_' + direction) : "") +
        (filters && filters["export"] ? ('&format=xlsx') : "") 
      )
      .map(
        res => {
          if(filters && filters["export"] ){
            return res;
          }
          let response = res.json();
          return response;
        },
        error =>{
          return [];
        });
  }

  showArtwork(artwork_id): Observable<Artwork> {
    return this
      ._apiService
      .get('artworks/' + artwork_id)
      .map(response => this._mh.assign(Artwork, response.json().artwork) as Artwork);
  }

  deleteArtwork(artwork_id): Observable<Artwork> {
    return this
      ._apiService
      .delete('artworks/' + artwork_id)
      .map(response => this._mh.assign(Artwork, response.json()) as Artwork);
  }

  objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();

    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '[' + key + ']');
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    function ignore(root){
        return Array.isArray(ignoreList)
            && ignoreList.some(function(x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
}

  create(artwork:Artwork): Observable<any> {
      let form = this.objectToFormData(artwork, 'artwork', null);

      this.headers = new HttpHeaders({
        'access-token': localStorage.getItem('accessToken'),
        'token-type': 'Bearer',
        'client': localStorage.getItem('client'),
        'uid': localStorage.getItem('uid')
      })
    ​
      return this
        .http
        .post(environment.apiUrl + '/artworks', form, {headers: this.headers}).pipe(
          map((response: any) => {;
              return response;
            }
          )
        );
  ​
  }

  updateArtwork(artwork:Artwork): Observable<any> {
    let form = this.objectToFormData(artwork, 'artwork', null);

    this.headers = new HttpHeaders({
      'access-token': localStorage.getItem('accessToken'),
      'token-type': 'Bearer',
      'client': localStorage.getItem('client'),
      'uid': localStorage.getItem('uid')
    })
  ​
    return this
      .http
      .post(environment.apiUrl + '/artworks/'+ artwork.id + '/update', form, {headers: this.headers}).pipe(
        map((response: any) => {;
            return response;
          }
        )
      );
​
  }

  deleteAttachment(id): Observable<any> {
    return this
      ._apiService
      .delete('attachments/' + id)
      .map(response => response.json());
  }

  publishArtwork(artwork, publish): Observable<any> {
    let url = "publish";
    if(!publish){
       url = "unpublish";
    }
    return this
      ._apiService
      .put('artworks/' + artwork.id + '/'+ url +'/', artwork)
      .map(response => response.json());
  }

  updateAttachments(attachments): Observable<any> {
    return this
      ._apiService
      .post('attachments/update', {"attachments":attachments}).pipe(
        map((response: any) => {;
            return response;
          }
        )
      );
  }

  publishAllArtworks(artworks): Observable<any> {
    return this
      ._apiService
      .post('artworks/publish_all', {'artworks':artworks})
      .map(response => response.json());
  }

  deleteAllArtworks(artworks): Observable<any> {
    return this
      ._apiService
      .post('artworks/remove_all', {'artworks':artworks})
      .map(response => response.json());
  }

  findArtworks(title): Observable<Object[]> {
    return this
      ._apiService
      .post('artworks/find_by_title', {'title':title})
      .map(response => response.json().artworks);
  }
}
