import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'm-switch-button',
	templateUrl: './switch-button.component.html',
	styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent implements OnInit {
	@Output() emitter: EventEmitter<any[]> = new EventEmitter();
	@Input() forceCheck: boolean;
	isChecked:any = false;

    ngOnInit() {
	}
	
	checkValue(event){
		this.emitter.emit(event);
	}
}