import { Injectable } from '@angular/core';

@Injectable()
export class ModelHelperService{
  /**
   * Clone an object preserving its Type and performing a null check
   *
   * @param type The type of the object to bopy the values into
   * @param value The object to be copied
   * @example
     class MyFieldClass { }
     class MyClass{
       private _myField: MyFieldClass;
       get myField() : MyFieldClass { return this._myField; }
       set myField(value: MyFieldClass) {
         this._myField = modelHelper.assign(MyFieldClass, value);
       }
     }
   */
  assign<T>(type: {new(): T}, value: T | T[]) : T | T[] {
    return ModelHelperService.assign(type, value);
  }
  static assign<T>(type: {new(): T}, value: T | T[]) : T | T[] {
    if(value instanceof Array) return ModelHelperService.assignCollection(type, value);
    return value && Object.assign(new type(), value);
  }

  /**
   * Clone a collection into a new array, cloning each member, preserving their Types and performing null checks
   *
   * @param type The type of the elements to instantiate inside the new array
   * @param value The array to copy the values from
   * @example
     class MyFieldClass { }
     class MyClass{
       private _myFields: MyFieldClass[];
       get myFields() : MyFieldClass[] { return this._myFields; }
       set myFields(value: MyFieldClass[]) {
         this._myFields = modelHelper.assignCollection(MyFieldClass, value);
       }
     }
   */
  private static assignCollection<T>(type: {new(): T}, value: T[]) : T[] {
    let output = value && [];
    for(let obj of value){
      output.push(ModelHelperService.assign(type, obj) as T);
    }
    return output;
  }


  isEmpty(obj) {
    let hasOwnProperty = Object.prototype.hasOwnProperty;
      // null and undefined are "empty"
      if (obj == null) return true;

      // Assume if it has a length property with a non-zero value
      // that that property is correct.
      if (obj.length > 0)    return false;
      if (obj.length === 0)  return true;

      // If it isn't an object at this point
      // it is empty, but it can't be anything *but* empty
      // Is it empty?  Depends on your application.
      if (typeof obj !== "object") return true;

      // Otherwise, does it have any properties of its own?
      // Note that this doesn't handle
      // toString and valueOf enumeration bugs in IE < 9
      // for (var key in obj) {
      //     if (hasOwnProperty.call(obj, key)) return false;
      // }
      let flag = true
      for (const [key, value] of Object.entries(obj)) {
        if(value !== ""){
          flag = false;
        }else if(value != null){
          flag = false;
        } else if ( value){
          flag = false;
        }
      }

      if(!flag) return false;

      return true;
  }

  
}
