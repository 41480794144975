import { Tag } from "../tag/tag";

export class Artwork {
  id?: string;
  acquisition: string;
  artworks_related: any;
  relates_artworks: any;
  authenticity_certificate: boolean;
  awards: string;
  code: string;
  collocation: string;
  contacts: string;
  current_status_of_work: string;
  customs_note: boolean;
  depth_it: number;
  depth_en: number;
  description_en: string;
  description_it: string;
  documentations: string;
  duration: string;
  edition: string;
  environmental_dimension: string;
  from_year: any;
  fund: string;
  height_it: number;
  height_en: number;
  item_count:number;
  market_price_amount:number;
  market_price_currency:string;
  market_price_notes:string;
  market_price_vat: number;
  other_acquisition: string;
  other_collocation: string;
  other_current_status: string;
  other_fund: string;
  other_provenance: string;
  provenance: string;
  published: boolean;
  purchase_price_amount: number;
  purchase_price_currency: string;
  purchase_price_notes: string;
  purchase_price_vat: number;
  resolution: string;
  title: string;
  to_year: any;
  typology: string;
  unit_of_measure: string;
  unit_of_measure_en:string;
  video_url_1: string;
  video_url_2: string;
  video_url_3: string;
  width_it: number;
  width_en: number;
  year_of_acquisition: number;
  artist_ids: string[];
  tag_ids: string[];
  photo: any;
  documentation_pdf: Array<Object>;
  authenticity_certificate_pdf: Object;
  customs_note_pdf: Object;
  images: any;
  hh?: string;
  mm?:string;
  ss?:string;
  artists?: any;
  tags?:Array<Tag>;
  selected?: any;
  variable_dimension?:boolean;
  variable_duration?:boolean;
  f_24?: boolean;
  f_24_pdf: any;
  market_price_total_amount: string;
  purchase_price_total_amount: string;
  in_progress: boolean;
  no_date: boolean;
}


